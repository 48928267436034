// Analytics header css..............

.analytics_header_wrapper {
  padding: 12px 20px;

  h5 {
    margin: 0;
    padding: 0;
  }

  .filter_profile_section {
    flex-wrap: wrap;
  }
}

// Analytics media card css................

.analytics_media_card_wrapper {
  padding-right: 15px;
  h3 {
    display: block;
    margin-bottom: 15px;
  }
  a {
    color: #42bdd0;
  }

  img {
    border-radius: 8px;
    max-height: 285px;
  }
}
.progress {
  height: 26px;
  border-radius: 0;
  --cui-progress-bg: #cef3ee;
  color: #344352;
  position: relative;

  span {
    position: absolute;
    top: 4px;
    left: 8px;
    color: var(--txt_color);
    font-size: 14px;
  }

  p {
    position: absolute;
    right: 8px;
    top: 4px;
    color: var(--txt_color);
    font-size: 14px;
  }
}

.progress-bar {
  background-color: #75e5d0;
  color: #344352;
}

//------Analytics detail page css-----

.analytics_details_wrapper {
  .analytics_details_content {
    img {
      width: 100%;
    }
  }
}

// ---Story Analytics CSS-------------

.story_analytics_wrapper {
  .progress {
    height: 45px;

    span,
    p {
      top: 8px;
      font-weight: 400;
      font-size: 19px;

      @media (max-width: 1199px) {
        font-size: 16px;
      }
    }

    p {
      font-weight: 700;
    }
  }
  .usage_section{
  .progress:last-child {
    .progress-bar {
      white-space: normal;
      padding: 10px;
      height: auto !important;
      p {
      position: static;
      padding-left: 90px;
      margin: 0;
      text-align: right;
      line-height: 20px;
      font-size: 18px;
    }
  }
}
  }

  h5 {
    font-size: 21px;

    a {
      color: var(--btn_color);
      text-decoration: none;
      margin-left: 6px;
    }
  }

  .progress_title {
    font-weight: 650;
  }
}
