.modal-dialog {
  .modal-header {
    border-bottom: none;
  }
  .modal-body {
    padding-top: 0px;
    .card {
    }
  }
}
