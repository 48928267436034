.slick-slide.slick-active.slick-current {
  margin-left: 140px;
}
.carousel_wrapper {
  margin-top: 30px;
  overflow: hidden;
  .slick-slider {
    button {
      display: none;
    }
  }
}

@media (max-width: 1700px) {
  .slick-slide.slick-active.slick-current {
    margin-left: 100px;
  }
}
@media (max-width: 1440px) {
  .slick-slide.slick-active.slick-current {
    margin-left: 120px;
  }
}
@media (max-width: 990px) {
  .slick-slide.slick-active.slick-current {
    margin-left: 163px;
  }
}
@media (max-width: 580px) {
  .slick-slide.slick-active.slick-current {
    margin-left: 0px;
  }
}
