.roles_table_wrapper {
  input.form-control.input {
    max-width: 400px;
  }

  svg {
    height: 18px;
    width: 18px;
    color: #42bdd0;
    cursor: pointer;
    &:hover {
      color: #6aa4ad;
    }
  }

  th,
  td {
    border-bottom: 0;
  }

  tr {
    border-bottom: 1px solid rgb(152, 186, 189);
  }
}

.dropdown-container {
  border: 1px solid #d8dbe0 !important;
  padding-top: 3.5px;
  padding-bottom: 3.5px;
  border-radius: 0.375rem;

  span.gray {
    color: #8a93a2;
  }
}
