// Here you can add other styles
.body {
  padding: 0px !important;
  background-color: var(--body_color);
  &.flex-grow-1 {
      .container-lg {
          max-width: 100%;
          width: 100%;
      }
  }
}
.simplebar-content-wrapper {
    overflow: auto !important;
}
.sidebar-backdrop.fade.show {
  display: none !important;
}
.container {
  &.full_width {
      max-width: 100%;
      width: 100%;
  }
}

.sub_heading {
  margin: 15px 0px;
}

.item_count {
  opacity: 0.6;
  font-size: 14px;
  margin-bottom: 0px;
}

.sidebar {
  --cui-sidebar-width: 46px !important;
  transition: all 0.5s ease-in-out;
  .sidebar-nav {
      .nav-link {
          span {
              display: none;
          }
      }
  }
  .sidebar-brand {
      position: relative;
      padding: 10px 0px;
      .toggle_arrow {
          opacity: 0;
          visibility: hidden;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translate(0, -50%) rotate(180deg);
          background-color: transparent;
          transition: all 0.7s ease-in-out;
          &::before {
              content: '';
              width: initial;
              height: initial;
              background-size: 22px;
              background-color: transparent;
              // font-family: fontAwesome;
              background-image: url('../../public/arrow_slide.png');
              color: #fff;
              font-size: 25px;
              width: 20px;
              height: 20px;
              background-size: contain;
          }
      }
      img {
          width: 40px;
      }
  }
  &.active {
      --cui-sidebar-width: 16rem !important;
      .sidebar-nav {
          .nav-link {
              span {
                  display: block;
              }
          }
      }
      .sidebar-brand {
          .toggle_arrow {
              opacity: 1;
              visibility: visible;
              transform: translate(0, -50%) rotate(0deg);
          }
      }
  }
}

.wrapper.main_right_content_wrapper {
  padding-left: 46px !important;
  transition: all 0.5s ease-in-out;
}

.sidebar.active+.wrapper.main_right_content_wrapper {
  padding-left: var(--cui-sidebar-occupy-start, 0) !important;
}

.btn_wrap {
  .icon {
      vertical-align: text-bottom;
  }
  .edit-icon {
      height: 35px;
      width: 35px;
      background: #42bdd0;
      display: inline-block;
      color: #fff;
      border-radius: 50%;
      text-align: center;
      padding-top: 5px;
      font-size: 12px;
      vertical-align: middle;
  }
  .edit-icon:hover {
      background: #2aa5b8;
  }
  button {
      border: 1px solid #000;
      padding: 10px 15px;
      border-color: var(--btn_color_grey);
      font-weight: bold;
      border-radius: 4px;
      color: var(--txt_color);
      cursor: pointer;
      &:disabled,
        &[disabled] {
            background-color: #d6d1d1;
            color: #393838;
            cursor: not-allowed;
        }
      &.active {
          background-color: var(--btn_color);
          border-color: var(--btn_border_color);
          color: #fff;
      }
      &.default_color {
          background-color: var(--btn_color);
          border-color: var(--btn_border_color);
          color: #fff;
      }
  }
  &.withbg {
      button {
          background-color: var(--btn_color);
          color: #fff;
          border-color: var(--btn_border_color);
      }
  }
}

.gap_bottom {
  margin-bottom: 10px;
}

input.form-control {
  &:focus {
      box-shadow: none;
  }
}
.input-group {
    textarea {
        width: 100% !important;
        height: 100px;   
    }
}
.react-tooltip {
    z-index: 999999;
    font-size: 14px !important;
}
.info_icon {
    margin:0 5px 0 5px;
}
.pos-rel {
    position: relative;
}
.picker-preview {
    display: inline-block;
    width: 50px;
    height: 37px;
    border-radius: 0 5px 5px 0;
    position: absolute;
    right: 12px;
    top: 30px;
}
.simplebar-content {
  .nav-link {
      display: inherit;
      flex: inherit;
      font-size: 16px;
      position: relative;
      padding: 10px;
      &::after {
          content: '';
          position: absolute;
          left: 0px;
          top: 0px;
          height: 100%;
          width: 0px;
          background-color: var(--btn_color);
          opacity: 0;
          visibility: hidden;
          -moz-transition: all 0.5s ease-in-out;
          -o-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out;
      }
      &.active {
          color: #fff;
          &::after {
              opacity: 1;
              visibility: visible;
              width: 100%;
              z-index: -1;
          }
      }
      img {
          margin-right: 0px;
          width: 100%;
          object-fit: contain;
          margin-left: 0px !important;
      }
  }
}

.sidebar-fixed.active {
  .sidebar-nav {
      .nav-link {
          display: flex;
          flex: 1 1;
          padding: 12px 10px;
          img {
              margin-right: 10px;
              width: 20px;
          }
      }
  }
}

.analytics_details_content {
    position: relative;
    .live-date {
        background: #3c4b64;
        display: inline-block;
        padding: 9px;
        margin-bottom: 21px;
        position: absolute;
        top: 1%;
        right: 0;
        color: white;
    }
}

.nav-underline {
  .nav-link {
      position: relative;
      &::after {
          content: '';
          position: absolute;
          left: 0px;
          bottom: 0px;
          width: 0px;
          height: 3px;
          background-color: var(--btn_color);
          opacity: 0;
          visibility: hidden;
          -moz-transition: all 0.5s ease-in-out;
          -o-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out;
      }
      &.active {
          color: var(--btn_color);
          &::after {
              opacity: 1;
              visibility: visible;
              width: 100%;
          }
      }
  }
}

.bg-light.login_page {
  background-color: var(--main_bg) !important;
  .card.text-white.bg-primary {
      display: none;
  }
  .card-body {
      .input-group-text {
          background-color: #ebedef;
          border-color: #d8dbe0;
          border-radius: 4px;
      }
      input.form-control {
          border-color: #d8dbe0;
          font-weight: normal;
          padding: 10px;
      }
  }
  .btn-primary.btn_login {
      background-color: var(--btn_color);
      border-color: var(--btn_border_color);
      padding: 12px 22px !important;
  }
  .btn_forgot {
      text-align: right;
      .btn-link {
          color: var(--btn_color);
      }
  }
}

.customized_form {
  &.register_form {
      .form_holder {
          width: 700px;
          margin: 0 auto;
      }
  }
  button {
      &.btn-success {
          background-color: var(--btn_color);
          border-color: var(--btn_border_color);
          padding: 12px 22px !important;
          color: var(--btn_wh_color);
      }
  }
  .input-group-text {
      background-color: #ebedef;
      border-color: #d8dbe0;
      border-radius: 4px;
  }
  input.form-control {
      border-color: #d8dbe0;
      font-weight: normal;
      padding: 10px;
      &:focus {
          outline: none;
          box-shadow: none;
      }
  }
  .regis_Select_wrap {
      select {
          padding: 10px;
          border-color: #d8dbe0;
          &:focus {
              outline: none;
              box-shadow: none;
          }
          option {
              // display: none;
              &:nth-child(1) {
                  opacity: 0.5;
              }
          }
      }
  }
  .file_wrap {
      position: relative;
      width: 100%;
      input[type='file'] {
          position: absolute;
          left: 0px;
          top: 0px;
          width: 100%;
          height: 100%;
          opacity: 0;
          z-index: 1;
      }
      .custom_upload {
          display: block;
          border: 1px solid #d8dbe0;
          border-radius: 5px;
          padding: 10px;
          opacity: 0.6;
      }
  }
}

i.dragger {
  color: red;
  font-size: 13px;
}

// customized form
// current stories
.main_stories_wrapper {
  margin-bottom: 60px;
  a {
      text-decoration: none;
      color: #b5b3b3;
  }
  .bg_stories {
      border-radius: 4px;
      padding: 14px;
      height: 263px;
      width: 100%;
      background-size: cover;
      p {
          background-color: #fcf1f1;
          color: #ed848d;
          padding: 10px;
          border-radius: 4px;
          text-align: center;
      }
  }
  .stories_list {
      img {
          display: block;
          // width: 100%;
          max-width: 100%;
          // height: 250px;
          // object-fit: cover;
          border-radius: 6px;
      }
      span {
          display: block;
          margin: 10px 0px;
      }
  }
}
.react-datepicker__input-container {
    display: inline;
}
.custom_header_wrapper {
  padding: 10px 20px;
}

.stories_header_wrapper {
  background-color: #fff;
  .holder_wrap {
      display: flex;
      flex-flow: row wrap;
      &>div {
          display: flex;
          flex-flow: column;
          justify-content: center;
      }
      .left_side {
          width: 400px;
          strong {
              color: var(--txt_color);
              span {
                  color: var(--btn_color);
                  font-weight: normal;
              }
          }
          a {
              color: #333333;
              text-decoration: none;
          }
      }
      .right_side {
          width: calc(100% - 400px);
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-end;
          .btn_wrap {
              button {
                  background-color: var(--btn_color);
                  color: #fff;
                  border: 1px solid var(--btn_border_color);
                  font-size: 14px;
                  padding: 5px 20px;
                  border-radius: 5px;
                  margin-right: 10px;
              }
          }
          .bucket_stories_btn_wrap {
            button {
                display: inline-block;
            }
            .btn_wrap {
                display: inline-block;
                button {
                    span{
                        display: inline-block;
                    }
                }
            }
          }
      }
  }
}

.per_stories_list {
  .stories_action_btn {
      .left_side {
          .btn_wrap {
              button {
                  display: inline-block;
                  margin-right: 10px;
              }
          }
      }
  }
}

.main_content_wrapper {
  padding: 0px 20px;
  min-height: calc(100vh - 60px);
  display: flex;
  flex-flow: column;
  margin-top: 0px;
  padding-top: 30px;
  background-color: #f8f9fb;
  .date_range_holder {
    position: relative;
    z-index: 999;
    .rdrDateRangePickerWrapper {
        position: absolute;
        right: 0;
        top: -34px;
        box-shadow: 2px 2px 11px #3c4b64;
    }
  }
  .dash_same_height_wrapper {
      padding-bottom: 30px;
      &>div {
          display: flex;
          flex-flow: column;
      }
      .inner_left_side {
          padding-right: 30px;
          .widget_filter_area {
              .card {
                  background-color: var(--btn_color) !important;
                  border: 1px solid #37a2b2;
                  border-radius: 5px;
                  .fw-semibold {
                      font-weight: normal;
                      color: #fff !important;
                  }
                  strong {
                      color: #fff;
                      font-weight: normal;
                  }
                  &.rate_wrapper {
                      .progress {
                          margin-top: 10px !important;
                          margin-bottom: 10px !important;
                          --cui-progress-bg: rgba(216, 219, 224, 0.5);
                          .progress-bar {
                              background-color: #fff !important;
                          }
                      }
                  }
                  &.time_wrapper {
                      background-color: #863b82 !important;
                      border-color: #622a5f;
                      .progress {
                          margin-top: 10px !important;
                          margin-bottom: 10px !important;
                          --cui-progress-bg: rgba(216, 219, 224, 0.5);
                          .progress-bar {
                              background-color: #fff !important;
                          }
                      }
                  }
                  &.clicks_wrapper {
                      background-color: #71da9e !important;
                      border-color: #5ab280;
                      .chart-wrapper {
                          margin: 0px !important;
                      }
                  }
                  &.performance_page_wrapper {
                      background-color: #58a4e2 !important;
                      border-color: #4585b9;
                      .chart-wrapper {
                          margin-top: 5px !important;
                      }
                  }
              }
          }
          ul.btn_filter {
              padding: 0px;
              margin-bottom: 0px;
              li {
                  display: inline-block;
                  margin-right: 15px;
              }
              button {
                  border: 1px solid #000;
                  padding: 10px 15px;
                  border-color: var(--border_color);
                  font-weight: bold;
                  border-radius: 4px;
                  &.btn_active {
                      background-color: var(--btn_color);
                      color: #fff;
                  }
              }
          }
      }
      .inner_right_side {
          background-color: #fff;
          padding: 30px;
          border: 1px solid #e9edf4;
          border-radius: 5px;
          margin-top: 45px;
          // sidebar acitivy
          .sidebar_activity {
              h2 {
                  font-size: 30px;
                  font-weight: bold;
              }
              ul {
                  padding: 0px;
                  .action_type_1 {
                      color: #cd2f2f;
                  }
                  li {
                      list-style: none;
                      display: block;
                      margin-bottom: 10px;
                      font-size: 20px;
                      span {
                          display: block;
                          a {
                              display: inline-block;
                              color: var(--btn_color);
                          }
                      }
                  }
              }
          }
      }
  }
  .list_of_stories_table_wrapper {
      .media_img {
          width: 100px;
          object-fit: cover;
          border-radius: 5px;
      }
      .btn_wrap {
          button {
              margin-right: 10px;
              &:last-child {
                  margin-right: 0px;
              }
          }
          &.btn_closest {
              button {
                  margin-right: 0px;
                  border-right: none;
                  border-radius: 0px;
                  &:first-child {
                      border-top-left-radius: 5px;
                      border-bottom-left-radius: 5px;
                  }
                  &:last-child {
                      border-right: 1px solid var(--btn_color_grey);
                      border-top-right-radius: 5px;
                      border-bottom-right-radius: 5px;
                  }
                  &.active {
                      border-color: var(--btn_border_color);
                  }
              }
          }
      }
      .select_form {
          width: 35px;
          display: inline-block;
          input {
              width: 35px;
              height: 35px;
              -webkit-appearance: none;
              position: relative;
              &::before {
                  content: '';
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  width: 100%;
                  height: 100%;
                  border-radius: 100%;
                  border: 2px solid var(--btn_color_grey);
              }
              &:checked {
                  &::before {
                      background-color: var(--btn_color);
                      border-color: var(--btn_color);
                  }
                  &::after {
                      content: '\f00c';
                      position: absolute;
                      font-family: fontAwesome;
                      left: 7px;
                      top: 2px;
                      color: #fff;
                      font-size: 20px;
                  }
              }
          }
      }
      table {
          tbody {
              tr {
                  td:last-child {
                      width: 250px;
                  }
              }
          }
      }
  }
  form.logo_upload_form {
      input[type="checkbox"] {
          width: 60px;
          height: 30px;
          -webkit-appearance: none;
          position: relative;
          vertical-align: middle;
          background: #dcdada;
          border-radius: 50px;
          &::before {
              content: '\f00d';
              color: #fff;
              border: none;
              border-radius: 100%;
              background: #fc0202;
              font-family: fontAwesome;
              font-size: 20px;
              position: absolute;
              left: 0px;
              top: 0px;
              width: 30px;
              height: 30px;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: all 0.5s ease-in-out;
          }
          &:checked {
              &::before {
                  background-color: var(--btn_color);
                  content: '\f00c';
                  font-family: fontAwesome;
                  left: 30px;
              }
          }
      }
  }
  // list_of_stories_table_wrapper
  .social_account_wrapper {
      table {
          max-width: 800px;
          width: 80%;
          tr {
              border: transparent;
              th {
                  text-align: left;
                  padding: 2px 0px;
              }
          }
          tbody {
              tr {
                  th,
                  td {
                      padding: 2px;
                  }
                  th {
                      font-weight: normal;
                  }
                  td {
                      font-weight: normal;
                  }
              }
          }
      }
  }
  .bg-circle-common {
      background: #3c4b64;
      height: 30px;
      width: 30px;
      display: inline-block;
      border-radius: 50%;
      vertical-align: middle;
      padding: 3px 1px 0 1px;
      color: #ffffff;
      margin-left: 5px;
      text-align: center;
  }
  .social-delete {
      background: red;
  }
  .social_account_icon {
      max-width: 25px;
      margin-right: 10px;
  }
  span.social_account_status {
      padding: 3px 15px;
      border-radius: 5px;
      font-size: 14px;
  }
  .disconnected {
      background-color: #ffe2e5;
      color: #f64e60;
      text-align: center;
      border: 1px solid #f64e60;
  }
  .connected {
      background-color: #e8fbea;
      color: #60ba71;
      text-align: center;
      border: 1px solid #60ba71;
  }
  .social-view {
      background: #42bdd0;
  }
  .analytics-view {
      background: #dc7004;
  }
  .footer {
      border-top: none;
      margin-top: auto;
      background-color: transparent;
      .footer_wrap>i {
          float: left;
          margin-top: 4px;
          display: inline-block;
          margin-right: 10px;
      }
      span.footer_wrap {
          display: block;
          width: 100%;
          p {
              margin-bottom: 0px;
          }
      }
  }
  .logo-container {
      position: relative;
      width: fit-content;
      height: fit-content;
      .reeled-home-url {
          position: absolute;
          top: 0;
          right: 20px;
          width: 50%;
          height: 96%;
          border: 2px solid red;
          cursor: move;
      }
      .resize-handle {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 10px;
          height: 10px;
          background-color: red;
          cursor: se-resize;
      }
  }
  // social_account_wrapper
  &.setting_page {
      background-color: #f1f2f4;
      background-color: #f8f9fb;
      position: relative;
      .logo_tab {
          color: #344352;
          h5 {
              position: relative;
              cursor: pointer;
              &::after {
                  content: '';
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  height: 100%;
                  width: 100%;
                  background-color: rgb(248 249 251 / 83%);
                  transition: all 0.5s ease;
                  z-index: 1;
              }
              &:hover {
                  &::after {
                      left: inherit;
                      right: 0px;
                      width: 0px;
                  }
              }
              transition: all 0.5s ease;
              &.active {
                  // opacity: 1;
                  &::after {
                      left: inherit;
                      right: 0px;
                      width: 0px;
                  }
              }
          }
      }
      .code_side {
          padding-left: 50px;
          justify-content: flex-start !important;
      }
      .code_generator_wrapper {
          strong {
              display: block;
          }
          .textarea {
              display: block;
              height: 100px;
              width: 100%;
              border-color: #e9edf4;
              resize: none;
              margin: 10px 0px;
              border-radius: 5px;
              border: none;
              padding: 10px;
              background-color: #ffffff;
              &:focus {
                  outline: none;
              }
          }
          .sm_logo_wrap {
              margin-bottom: 20px;
              span {
                  display: inline-block;
                  border-radius: 4px;
                  padding: 1px 0px;
                  font-size: 20px;
                  margin-right: 12px;
                  &:last-child {
                      margin-right: 0px;
                  }
              }
          }
      }
      .container {
          &.full_width {
              position: relative;
              z-index: 1;
          }
      }
      &::after {
          content: '';
          position: absolute;
          right: 0px;
          top: 0px;
          width: 50%;
          height: 100%;
          background-color: #f1f2f4;
      }
      .branding_wrapper {
          max-width: 500px;
          width: 100%;
          h5 {
            font-size: 18px;
            font-weight: normal;
          }
          .react-datepicker-wrapper {
              display: block;
              .react-datepicker__input-container {
                  input {
                      font-size: 1rem;
                      padding: 0.375rem 0.75rem;
                  }
              }
          }
      }
  }
  .logo_upload_form {
      ul {
          padding: 0px;
          li.custom_upload {
              list-style: none;
              display: inline-block;
              margin-right: 30px;
              .preview_img_wrap {
                  width: 300px;
                  height: 100px;
                  margin: auto;
                  display: flex;
                  flex-flow: column;
                  justify-content: center;
                  position: relative;
                  img {
                      width: 100% !important;
                      height: 100%;
                      object-fit: contain;
                      max-height: 130px;
                  }
                  .btn_close {
                      position: absolute;
                      right: -25px;
                      top: -10px;
                      border: none;
                      box-shadow: none;
                      background: transparent;
                      color: #ff8d84;
                      font-size: 18px;
                  }
              }
              label {
                  display: block;
                  text-align: center;
                  margin-top: 10px;
              }
              .click_fn_wrapper {
                  position: relative;
                  &>span {
                      display: block;
                      text-align: center;
                      color: var(--btn_color);
                      text-decoration: underline;
                      cursor: pointer;
                  }
                  input[type='file'] {
                      position: absolute;
                      left: 0px;
                      top: 0px;
                      width: 100%;
                      height: 100%;
                      opacity: 0;
                      cursor: pointer;
                  }
                  input[type='file']::-webkit-file-upload-button {
                      /* chromes and blink button */
                      cursor: pointer;
                  }
              }
              &:last-child {
                  margin-right: 0px;
              }
          }
      }
  }
  .analytics_overview {
      background-color: #fff;
      padding: 15px;
      border: 2px solid #e9edf4;
      border-radius: 8px;
      h3 {
          padding: 0px 15px;
          display: block;
          margin: 0px;
      }
      .any_widget_wrapper {
          position: relative;
          .extra_sm_txt {
              position: absolute;
              right: 15px;
              bottom: 37px;
              span {
                  font-size: 14px;
                  color: #aaafb8;
              }
          }
          .card {
              border: none;
              background-color: transparent;
              &.default_widget_color {
                  .progress {
                      height: 5px !important;
                      border-radius: 4px;
                      background-color: rgba(66, 189, 208, 0.25);
                      .progress-bar {
                          background-color: var(--bar_color);
                          border-radius: 4px;
                      }
                  }
              }
          }
          .bottom_head {
              padding: 0px 15px;
          }
      }
  }
}

// main_content_wrapper
.list_of_user_table_wrapper {
  a {
      color: var(--btn_color);
  }
  .select_form {
      width: 30px;
      display: inline-block;
      input {
          width: 30px;
          height: 30px;
          -webkit-appearance: none;
          position: relative;
          &::before {
              content: '';
              position: absolute;
              left: 0px;
              top: 0px;
              width: 100%;
              height: 100%;
              border-radius: 5px;
              border: 2px solid var(--btn_color_grey);
              -moz-transition: all 0.5s ease-in-out;
              -o-transition: all 0.5s ease-in-out;
              transition: all 0.5s ease-in-out;
          }
          &:checked {
              &::before {
                  background-color: var(--btn_color);
                  border-color: var(--btn_color);
              }
              &::after {
                  content: '\f00c';
                  position: absolute;
                  font-family: fontAwesome;
                  left: 7px;
                  top: 2px;
                  color: #fff;
                  font-size: 16px;
              }
          }
      }
  }
}

// list_of_user_table_wrapper
//---user details----
.btn_wrap {
  button {
      background: transparent;
      padding: 6px 18px;
      font-size: 14px;
  }
}

.user_details_btn_wrapper {
  button.btn.btn-success {
      background-color: #622a5f;
  }
  .btn_wrap {
      button {
          width: 100%;
      }
  }
}

//----user details end----
// manage your details form
.side_by_side_form_field {
  .label_wrapper {
      display: flex;
      flex-flow: row wrap;
      label {
          width: 150px;
      }
      .input-group {
          width: calc(100% - 150px);
      }
  }
  &.set_password_wrapper {
      .label_wrapper {
          margin-bottom: 20px;
          button {
              width: auto;
              border: none;
          }
      }
      .btn_wrap {
          button {
              width: auto;
              padding: 10px 20px;
          }
      }
  }
}

.customized_card {
  border: none;
  background: transparent;
}

// manage your details form
.unisocial-header-wrapper {
  z-index: 12 !important;
}

.dropdown-menu {
  padding-bottom: 0px;
  li {
      &:first-child {
          h6 {
              background-color: #3c4b64 !important;
              color: #fff;
              border-radius: 4px 4px 0px 0px;
          }
      }
  }
}

.dropdown-item:hover {
  background-color: var(--btn_color);
  color: #fff;
}

// pagination css start
nav.pagination_wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: end;
}

.pagination_wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: end;
}

.active>.page-link,
.page-link.active {
  background-color: #42bdd0 !important;
  border-color: #42bdd0 !important;
}

ul.pagination>.page-item>.page-link+a {
  color: #3d4b64;
  text-decoration: none;
  font-size: 23px;
}

div.pagination>a {
  border-radius: 4px !important;
}

div.pagination>a+.page-item a {
  border-radius: 6px;
  color: #3d4b64;
  margin-right: 3px !important;
  cursor: pointer;
}

div.pagination .page-item.active {
  margin-right: 6px !important;
  margin-left: 6px;
}

div.pagination .page-item.active span.page-link {
  background-color: #3d4b64;
  color: #fff;
  border-color: #3d4b64;
}

// pagination end
form.add_website_wrapper {
  padding: 30px;
  border-radius: 4px;
  max-width: 500px;
  width: 80%;
  margin-left: 14px;
  margin-top: 17px;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 15%);
}

form.add_website_wrapper strong {
  display: block;
  margin-bottom: 8px;
}

form.add_website_wrapper input {
  width: 100%;
  border: 1px solid #c2c2c2;
  margin-bottom: 12px;
  padding: 10px;
  border-radius: 5px;
  &:focus {
      outline: none;
      box-shadow: none;
  }
}

form.add_website_wrapper .btn {
  width: 150px;
  background-color: var(--btn_color);
  color: #fff;
  border: none;
}

.any_widget_wrapper {
  .fs-4 {
      font-size: 18px;
      color: var(--txt_color);
      +div {
          font-weight: normal;
          color: #aaafb8;
          font-size: 14px;
      }
  }
}

.bottom_head p {
  color: #aaafb8;
  font-size: 14px;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 15px 10px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  font-size: 14px;
}

.react-datepicker__header {
  background: #3d4b64;
}

.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__current-month {
  color: #fff;
}

.react-datepicker__day-name,
.react-datepicker__time-name {
  color: #fff;
}

.react-datepicker__day {
  color: #3d4b64;
}

.react-datepicker__day--selected {
  color: #fff;
  background-color: #42bdd0;
}

.react-datepicker__input-container input:focus {
  outline: none;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #42bdd0;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover,
.react-datepicker__day--selected:hover {
  background-color: #cdf9ff;
  color: #3d4b64;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background-color: #cdf9ff;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  color: #3d4b64;
}

// responsive css
@media (max-width: 992px) {
  .main_content_wrapper {
      .dash_same_height_wrapper {
          .inner_left_side {
              padding-right: 0px;
          }
      }
  }
  .main_stories_wrapper {
      margin-bottom: 30px;
  }
  .per_stories_list {
      .stories_action_btn {
          .right_side {
              margin-top: 10px;
          }
      }
  }
  .main_content_wrapper {
      .list_of_stories_table_wrapper {
          tbody {
              tr {
                  td {
                      .btn_wrap {
                          button {
                              font-size: 14px;
                              width: 138px;
                          }
                      }
                      &:last-child {
                          .btn_wrap {
                              button {
                                  width: 120px;
                              }
                          }
                      }
                  }
              }
          }
      }
      &.setting_page {
          .code_side {
              padding-left: 0px;
              background-color: #f1f2f4;
              position: relative;
              min-height: 100vh;
              height: auto;
              padding-top: 30px;
              &::after,
              &::before {
                  content: '';
                  position: absolute;
                  right: -1.5rem;
                  top: 0px;
                  width: 1.5rem;
                  height: 100%;
                  background-color: #f1f2f4;
              }
              &::before {
                  right: inherit;
                  left: -1.5rem;
              }
          }
          &::after {
              display: none;
          }
      }
  }
}

// 992px
@media (max-width: 768px) {
  .main_stories_wrapper {
      margin-bottom: 20px;
  }
  .main_content_wrapper {
      &.setting_page {
          .logo_upload_form {
              ul {
                  li {
                      margin-bottom: 15px;
                      &.custom_upload {
                          label {
                              text-align: left;
                          }
                          .click_fn_wrapper {
                              max-width: 139px;
                              width: 100%;
                              &>span {
                                  text-align: left;
                              }
                          }
                      }
                      &:last-child {
                          margin-bottom: 0px;
                      }
                  }
              }
          }
      }
  }
}

@media (max-width: 768px) {
  html:not([dir='rtl']) .sidebar:not(.sidebar-end):not(.show) {
      margin-left: 0px !important;
  }
  .sidebar {
      .sidebar_logo_wrapper {
          position: relative;
          .no_click {
              position: absolute;
              left: 0px;
              top: 0px;
              width: 100%;
              height: 100%;
              z-index: 1;
          }
      }
  }
}

.top_link_header {
  color: var(--cui-body-bg-rgb);
  font-size: 14px;
  padding-top: 4px;
  &:hover {
      color: var(--btn_color);
  }
}

.no_right_margin {
  button {
      margin-right: 0px !important;
  }
}

.ql-editor {
  min-height: 300px !important;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}

.ql-container,
.ql-toolbar,
.ql-snow .ql-picker {
  font-size: 16px !important;
}

.story_analytics_wrapper {
  .row {
      div:first-child {
          .progress:last-child {
              height: auto;
              .progress-bar {
                  margin-left: auto;
              }
          }
      }
  }
}