@import 'variables';

.unisocial-header-wrapper {
  background-color: #ffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 0;
  position: relative;
  z-index: 1;

  .unisocial-header-tabs {
    display: flex;
    flex-wrap: wrap;
  }

  a.nav-link {
    color: black;
    padding-bottom: 13px;
  }
}
