.super_admin_dashboard {
  .super_admin_dashboard_image_wrapper {
    padding: 5px;
    background-color: #ffffff;
    border-radius: 50%;
    margin-right: 15px;

    img {
      vertical-align: unset;
    }
  }

  .table_row_suspended {
    opacity: 0.3;
  }

  .uni_name {
    color: var(--btn_color);
    font-weight: 400;
    cursor: pointer;
  }
}

button.border-0.bg-white.delete_uni_button {
  color: #5a4b58;
  font-size: 16px;
}
